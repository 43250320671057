import React from 'react';

import { IChallengePageProps } from './interfaces';
import { useSettings } from '@wix/tpa-settings/react';
import {
  IWixSDKContext,
  useEnvironment,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { st, classes } from './Widget.st.css';
import { UserState } from '../../../contexts/User/UserContext';
import settingsParams from '../settingsParams';
import { IChallengeSettings } from '../Settings/challengeSettings/challengeSettings.types';
import { ChallengeCompletedModal } from '../../../components-shared/ChallengeCompletedModal/ChallengeCompletedModal';
import { LeaveChallengeModalProvider } from '../../../contexts/LeaveChallengeModal/LeaveChallengeModalProvider';
import { withProviders } from '../../../contexts/main/withProviders';
import { isFullscreenMode } from '../../../contexts/Fullscreen/FullscreenProvider';
import { FCWithChildren } from '@wix/challenges-web-library';
import SidebarLayoutForParticipant from './views/SidebarLayout/SidebarLayoutForParticipant';

export const ParticipantPage: FCWithChildren<
  IChallengePageProps & IWixSDKContext
> = (props) => {
  const { t } = useTranslation();
  const { isEditor, isViewer, isMobile, language } = useEnvironment();

  const settings = useSettings();
  const pageRef = React.useRef<HTMLDivElement>();

  const settingsProp: IChallengeSettings = {} as any;
  Object.keys(settingsParams).map((key) => {
    settingsProp[key] = settings.get(settingsParams[key]);
  });

  const { userType, challengeData } = props;

  /*
    We show a Participant page is case of:
      - this is regular Participant Page;
      - this is Editor and owner work with settings for Participant page;
      - this is preview of Participant Page from BM.
    Also we need to force show Visitor page in case of owner wants to preview it from BM.
   */

  React.useEffect(() => {
    props.hideLoader && props.hideLoader();
  }, []);

  if (!challengeData?.challenge && isViewer) {
    if (userType === UserState.VISITOR) {
      props.promptLogin();
    }

    return <p className={classes.noAccess}>{t('challenge.no-access')}</p>;
  }

  return (
    <div
      ref={pageRef}
      data-hook="program-page-main"
      className={st(classes.root)}
    >
      <div data-hook="challenge-page">
        <div id="modal-root" />
        <div id="toast-root" />
        <LeaveChallengeModalProvider showModal={null}>
          <SidebarLayoutForParticipant
            {...props}
            isEditor={isEditor}
            isMobile={isMobile}
            t={t}
            pageRef={pageRef}
            settings={settingsProp as any}
            lng={language}
            isFullscreen={isFullscreenMode()}
          />
        </LeaveChallengeModalProvider>
        <ChallengeCompletedModal />
      </div>
    </div>
  );
};

ParticipantPage.displayName = 'ParticipantContainer';

export default withProviders(ParticipantPage);
