export enum Challenges {
  enableVisibilityByName = 'specs.challenges.OOIEnableVisibilityByName',
  enableAutopilot = 'specs.challenges.BMEnableAutopilot',
  enableAutopilotEditorX = 'specs.challenges.BMEnableAutopilotEditorX',
  enableDripContent = 'specs.challenges.WebMemberDripContent',
  skipMAInstallation = 'specs.programs.OOISkipMAInstallationUsers',
  enableUndoButton = 'specs.programs.OOIEnableUndoButton',
  hideStartDate = 'specs.challenges.OOIHideStartDateSelection',
  skipMAGroupsInstall = 'specs.challenges.skipInstallationsGroupsAndMA',
  enabledRenderSeoMetatagsList = 'specs.challenges.OOIRenderSEOMetaTagsList',
  enableProgramFieldSEOMetatags = 'specs.challenges.OOIRenderSeoMetatagsProgram',
  enableImageUrlFieldSEOMetatags = 'specs.challenges.OOISeoImageUrl',
  enableHideTeamEmail = 'specs.challenges.OOIHideSupportEmail',
  showSettingsPanel = 'specs.programs.ShowPaymentPageSettingsStudio',
  enableSeoMetatagsPayement = 'specs.programs.OOISeoMetatagsPaymentPage',
  enableSeoMetatagsThankYou = 'specs.programs.OOISeoMetatagsThankYouPage',
}
